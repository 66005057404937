var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.pageTitle)+" ")]),(_vm.config.bo_use_category == 1)?_c('v-sheet',{staticClass:"ml-4",attrs:{"width":"150"}},[_c('cate-select',{attrs:{"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}}})],1):_vm._e(),_c('search-field',{attrs:{"items":_vm.searchItems,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}}}),_c('v-spacer'),_c('v-btn',{attrs:{"to":("/board/" + _vm.table + "?act=write"),"color":"primary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" 새 팝업 추가 ")],1)],1),_c('v-data-table',{staticClass:"fixedTable",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.getNo(index))+" ")]}},{key:"item.wr_title",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"pa-0 justify-start",attrs:{"block":"","plain":""},on:{"click":function($event){return _vm.popupView(item)}}},[_vm._v(" "+_vm._s(item.wr_title)+" ")])]}},{key:"item.wr_2",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.wr_2).format("YYYY-MM-DD HH:mm"))+" ")]}},{key:"item.wr_3",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.wr_3).format("YYYY-MM-DD HH:mm"))+" ")]}},{key:"item.wr_1",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.wr_1)+"일 ")]}},{key:"item.wr_9",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-checkbox',{attrs:{"true-value":"1","false-value":"0","readonly":""},model:{value:(item.wr_9),callback:function ($$v) {_vm.$set(item, "wr_9", $$v)},expression:"item.wr_9"}})],1)]}},{key:"item.cmd",fn:function(ref){
var item = ref.item;
return [_c('tooltip-btn',{attrs:{"label":"미리보기","icon":"","color":"primary"},on:{"click":function($event){return _vm.popupView(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('tooltip-btn',{attrs:{"label":"수정","icon":"","color":"primary","to":("/board/" + _vm.table + "/" + (item.wr_id) + "?act=write")}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),(_vm.isSuper)?_c('tooltip-btn',{attrs:{"label":"삭제","icon":"","color":"red","loading":_vm.deleteLoading},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}])}),(_vm.curItem)?_c('popup-view',{attrs:{"item":_vm.curItem,"table":_vm.table},on:{"onClose":_vm.popupClose}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }